import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const TOKEN = 'token';

const initialAuthState = {
        currentUser: null,
        isAuthenticated: false,
        loading: false,
        error: null
}

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async ({ username, password }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/login', { username, password });
            window.localStorage.setItem(TOKEN, data.token);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logoutUser',
    async () => {
        window.localStorage.removeItem(TOKEN);
        // history.push('/home');
    }
);

export const fetchCurrentUser = createAsyncThunk(
    'auth/fetchCurrentUser',
    async (_, { rejectWithValue }) => {
        const token = window.localStorage.getItem(TOKEN);
        if (token) {
            try {
                const { data } = await axios.get('/auth/me', {
                    headers: {
                        authorization: token,
                    },
                });
                return data;
            } catch (error) {
                return rejectWithValue(error.response.data);
            }
        }
    }
);

export const signUpUser = createAsyncThunk(
    'auth/signUpUser',
    async(userData, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('auth/signup', userData);
            window.localStorage.setItem(TOKEN, data.token);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        
    },
    extraReducers: {
        [loginUser.pending]: (state) => {
            state.loading = true;
        },
        [loginUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.currentUser = action.payload;
            state.error = null;
        },
        [loginUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
            state.currentUser = null;
        },
        [fetchCurrentUser.pending]: (state) => {
            state.loading = true;
        },
        [fetchCurrentUser.fulfilled]: (state, action) => {
            state.currentUser = action.payload;
            state.isAuthenticated = !!action.payload; // Assuming payload is user object or null
            state.loading = false;
        },
        [fetchCurrentUser.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },
        [logoutUser.fulfilled]: (state) => {
            state.currentUser = null;
            state.isAuthenticated = false;
        },
        [signUpUser.pending]: (state) => {
            state.loading = true;
        },
        [signUpUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.isAuthenticated = true;
            state.currentUser = action.payload;
            state.error = null;
        },
        [signUpUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
            state.currentUser = null;
        },
    },
});

// export const { setAuth } = authSlice.actions;
export default authSlice.reducer;