import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../../features/auth';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, error } = useSelector(state => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(credentials));
  };

  return (
    <>
    
    <div className="flex relative justify-center mx-auto accent-color-dark br-5 p-46 box-shadow" style={{ maxWidth: 420 }}>
    {/* <div>
          <h2 className="absolute text-white pl-16">Login</h2>
        </div> */}
    
      <form onSubmit={handleSubmit} className='mt-24 justify-center'>
        <div>
          <input 
            type="text" 
            name="username"
            placeholder='   Enter your username'
            value={credentials.username}
            className='input-box mx-auto'
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input 
            type="password" 
            name="password"
            placeholder='   Enter your password'
            value={credentials.password} 
            className='input-box mx-auto'
            onChange={handleChange}
            required
          />
        </div>
          <div class="policy">
              <input type="checkbox" className='m-8'/>
              <h3 className='text-white py-12'>Show password</h3>
          </div>
        <button type="submit" className='px-48 py-18 text-white bg-color-dark no-border bold br-5 w-full md:auto nav-button'>Login</button>
      </form>
      {error && <div className="error-message">{error}</div>}
      </div>
    </>
  )
}

export default Login;
