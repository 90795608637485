import React from "react";
import './index.css';
import { Link } from 'react-router-dom';

const SubServiceCard = ({ src, service, copy }) => {
    return (
        <>
            <Link to='/services' className="no-underline mx-auto sub-service-link">
                <div className="card md:minh-300 mt-24 px-18 py-12 text-white mx-auto">
                    <div className="sub-service-mx-img-sec mx-auto">
                        <img src={src}
                            className="sub-service-mx-img-sec mx-auto br-10"
                        />
                    </div>
                    <h2
                        className="text-accent-dark text-center mt-16 py-12">
                            {service}
                    </h2>
                    <p className="text-center px-18 py-12">
                        {copy}  
                    </p>
                </div>
            </Link>
        </>
    )
}

export default SubServiceCard