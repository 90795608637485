// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-box {
    height: 2.625rem;
    margin: 1rem;
    border-radius: 10px;
    border:none;
    width: 300px;
}

.policy{
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./client/components/pages/Signup/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".input-box {\n    height: 2.625rem;\n    margin: 1rem;\n    border-radius: 10px;\n    border:none;\n    width: 300px;\n}\n\n.policy{\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
