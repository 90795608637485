import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from './components/Header'
import { Routes, Route, Navigate } from 'react-router-dom'
import { navItems } from './components/structure/navigation'
import { fetchCurrentUser } from './features/auth'



const App = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(fetchCurrentUser());
    }, [dispatch]);

    return (
        <>
            <Header />
            <Routes>
                { navItems.map((item, index) => (
                    <Route key={index} path={item.path} element={
                        item.isPrivate && !isAuthenticated? <Navigate to="/login"/> : item.element} />
                    ))
                }
            </Routes>
        </>
    )
}

export default App;