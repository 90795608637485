import React from 'react'
import HeroSection from '../../HeroSection'
import SubServiceSection from '../../SubServiceSection'

const Home = () => {
  return (
    <>
      <HeroSection />
      <SubServiceSection />
    </>
  )
}

export default Home