import React from "react"
import About from "../pages/About"
import Blog from "../pages/Blog"
import Dashboard from "../pages/Dashboard"
import Home from "../pages/Home"
import Login from "../pages/Login"
import Projects from "../pages/Projects"
import Signup from "../pages/Signup"
import Services from "../pages/Services"

export const navItems = [
    {   path: '/',          name: 'Home',       element: <Home />,      isMenu: true,   isPrivate: false },
    {   path: '/dashboard', name: 'Dashboard',  element: <Dashboard/>,  isMenu: true,   isPrivate: true },
    {   path: '/about',     name: 'About',      element: <About />,     isMenu: true,   isPrivate: false },
    {   path: '/services',  name: 'Services',   element: <Services />,  isMenu: true,   isPrivate: false },
    {   path: '/blog',      name: 'Blog',       element: <Blog />,      isMenu: false,  isPrivate: false },
    {   path: '/projects',  name: 'Projects',   element: <Projects />,  isMenu: false,  isPrivate: true },
    {   path: '/login',     name: 'Login',      element: <Login/>,      isMenu: false,  isPrivate: false },
    {   path: '/signup',    name: 'SIGNUP',     element: <Signup/>,     isMenu: false,  isPrivate: false },
];