import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <div className="px-24 pt-12">
            <Link to="/">
                <img
                    src="assets/images/Beck Develops Updated.svg"
                    alt="Beck Develops"
                    style={{ height: 100 }}
                />
            </Link>
        </div>
    );
};

export default Logo;