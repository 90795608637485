// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    min-width: 250px;
    max-width: 400px;
    /* border: 1px solid red; */
    
}  

.sub-service-mx-img-sec {
    max-width: 300px;
    max-height: 300px;
    margin: 0 auto;
}

.sub-service-link {
    transition: transform 0.7 ease;
}

.sub-service-link:hover {
    transform: scale(1.125);
    opacity: 0.50;
}`, "",{"version":3,"sources":["webpack://./client/components/SubServiceCard/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,2BAA2B;;AAE/B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".card {\n    min-width: 250px;\n    max-width: 400px;\n    /* border: 1px solid red; */\n    \n}  \n\n.sub-service-mx-img-sec {\n    max-width: 300px;\n    max-height: 300px;\n    margin: 0 auto;\n}\n\n.sub-service-link {\n    transition: transform 0.7 ease;\n}\n\n.sub-service-link:hover {\n    transform: scale(1.125);\n    opacity: 0.50;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
