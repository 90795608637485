import React from 'react';
import Logo from '../Logo';
import Nav from '../Nav';
import LoginSignUp from '../LoginSignUp';
import MobileMenu from '../MobileMenu';

 const Header = () => {
    return (
        <header>
            <div className="flex justify-between items-center relative z-10 mx-auto mx-width">
                <Logo />
                <Nav />
                <LoginSignUp />
                <MobileMenu />
            </div>
        </header>
    );
 }

 export default Header;
