import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import { store } from './store'
import { Provider } from 'react-redux';
import { BrowserRouter as Router  } from 'react-router-dom';

ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('app') // make sure this is the same as the id of the div in your index.html
  );