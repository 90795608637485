import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../features/auth';

const LoginSignUp = () => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logoutUser());
        navigate('/');
    }

    return (
        <div className="inline hidden md:flex">
            {!isAuthenticated ? (
                <>
                <Link to="/login" className="text-white px-18 py-12 m-8 no-underline nav-hover">
                    Login
                </Link>
                <Link to="/signup">
                    <button type="button" className="px-24 py-12 m-36 accent-color-dark no-border text-white br-10 bold uppercase letter-spacing nav-button">
                        Sign Up
                    </button>  
                </Link>
                </>
            ) : (
                <button onClick={handleLogout} className="text-white accent-color-dark no-border px-24 py-12 m-8 no-underline nav-hover bold uppercase letter-spacing nav-button">
                    Logout
                </button>
            )}
        </div>
    )
}

export default LoginSignUp;