import React from "react";
import { serviceItems } from "../structure/services";
import SubServiceCard from "../SubServiceCard";

const SubServiceSection = () => {
    return (
        <section className="flex wrap mt-24 px-24 py-12">
                {serviceItems.map((serviceItem, index) => {
                    return <SubServiceCard key={index} src={serviceItem.src} service={serviceItem.service} copy={serviceItem.copy}  />
                })}
        </section>
    )
}

export default SubServiceSection