import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signUpUser } from '../../../features/auth';
import './index.css';


const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, error} = useSelector(state => state.auth);

  useEffect(()=>{
    if (isAuthenticated){
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: ''
    });

  const handleChange = (e) => {
    setUserData({
        ...userData,
        [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signUpUser(userData));
};

  return (
    <>
    <div className='bg-color-dark h-100 h-full'>
      <div className="flex relative justify-center mx-auto accent-color-dark br-5 p-46 box-shadow" style={{ maxWidth: 420 }} >
        <div>
          <h2 className="absolute text-white pl-16">Sign Up</h2>
        </div>
        
        <form onSubmit={handleSubmit} className='mt-24 justify-center'>
          <div className=''>
              {/* <label>First Name:</label> */}
              <input 
                  type="text" 
                  name="firstName"
                  placeholder="   Enter your first name"
                  value={userData.firstName}
                  className="input-box mx-auto"
                  onChange={handleChange}
                  required
              />
          </div>
          <div>
              {/* <label>Last Name:</label> */}
              <input 
                  type="text" 
                  name="lastName"
                  placeholder="   Enter your last name"
                  value={userData.lastName}
                  className="input-box" 
                  onChange={handleChange}
                  required 
              />
          </div>
          <div>
              {/* <label>Email:</label> */}
              <input 
                  type="email"
                  name="email"
                  placeholder="   Enter your email"
                  value={userData.email}
                  className="input-box" 
                  onChange={handleChange}
                  required
              />
          </div>
          <div>
              {/* <label>Username:</label> */}
              <input 
                  type="text" 
                  name="username"
                  placeholder="   Enter a username" 
                  value={userData.username}
                  className="input-box" 
                  onChange={handleChange}
                  required
              />
          </div>
          <div>
              {/* <label>Password:</label> */}
              <input 
                  type="password" 
                  name="password"
                  placeholder="   Create password"
                  value={userData.password}
                  className="input-box" 
                  onChange={handleChange}
                  required 
              />
          </div>
          <div class="policy">
            <input type="checkbox" required className='m-8'/>
            <h3 className='text-white py-12'>I accept all terms & condition</h3>
          </div>
          <button 
            type="submit"
            className='px-48 py-18 text-white bg-color-dark no-border bold br-5 w-full md:auto nav-button'
          >I'm Ready!</button>
      </form>
      {
        error && <div className="error-message">{error}</div>
      }
      </div>
      </div>
  </>
  )
}

export default Signup