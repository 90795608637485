import React from 'react'
import { Link } from 'react-router-dom';

function About() {
  return (
    <section className="bg-color-dark">
      <div className="bg-color-dark mx-img-section">
          <img
              src="assets/images/Teman Suit Standing.png"
              alt="Teman Beck in Gray Suit"
              className="absolute mx-img-section bg-color-dark py-12 pl-16 sm:flex opacity-light sm:opacity-full"
              
              
          />
      </div>
      <div className='relative text-white sm:pl-50per px-24 py-12 mt-24'>
        <div>            
          <h2 className='px-18 py-12 text-center mt-42'>
            Hello! I'm
            <span className='text-accent-dark'> Teman Beck</span>
            , 
          </h2> 
            <h3 className='text-white text-center px-18 letter-spacing-13'>the driving force behind Beck Develops.</h3>
            <hr className='mt-16 text-secondary-dark' style={{ maxWidth: 540 }}/>              
            <p className='px-18 py-12 mt-16 mx-auto letter-spacing-13' style={{ maxWidth: 440 }}>
              My journey into the realm of software engineering is a tale of evolution, marked by diverse experiences that range from providing cutting-edge technical support at Hewlett Packard to managing the intricate electrical systems of a $10 billion America-class amphibious assault ship in the US Navy. This unique path has not only honed my technical skills but also instilled a profound sense of discipline, leadership, and an unyielding commitment to excellence.
            </p>
        </div>
        <hr className='mt-16 mx-auto text-secondary-dark' style={{ maxWidth: 80 }}/> 
        <div>
          <p className='px-18 py-12 mt-16 mx-auto letter-spacing-13' style={{ maxWidth: 440 }}>
            Starting in the tech industry with Hewlett Packard, I tackled complex technical challenges head-on, enhancing my problem-solving abilities and deepening my understanding of technology’s role in empowering individuals and organizations. This experience laid the foundation for my technical acumen and customer-centric approach.
          </p>  
        </div>
        <hr className='mt-16 mx-auto text-secondary-dark' style={{ maxWidth: 80 }}/> 
        
      </div>
      <div className='px-48'>
        <div className='text-white'>
          <p className='py-12 mt-16 mx-auto letter-spacing-13' style={{ maxWidth: 840 }}>
            Transitioning to the Navy, I was entrusted with the electrical grid's integrity and the training of over 30 personnel, a responsibility that sharpened my leadership qualities and my ability to perform under pressure. The challenges I faced and overcame in the Navy taught me invaluable lessons in resilience, teamwork, and the importance of meticulous planning and execution.
          </p>   
        </div>
        <hr className='mt-16 mx-auto text-secondary-dark' style={{ maxWidth: 80 }}/>
      </div>
      <div className='relative text-white'>
        <div className='px-48'>
          <p className='py-12 mt-16 mx-auto letter-spacing-13' style={{ maxWidth: 840 }}>Fueled by a passion for technology that was ignited by the release of World of Warcraft in 2004, my post-naval career saw me diving deep into the world of software development. Armed with a Software Developer Certification from Fullstack Academy and extensive studies in Computer Science at the University of Maryland Global Campus, I've since mastered a broad spectrum of technologies, including Java, JavaScript, SQL, React, Redux, and cloud services.
          </p>         
        </div>
        <hr className='mt-16 mx-auto text-secondary-dark' style={{ maxWidth: 80 }}/>
        <div className='px-48'>
          <p className='py-12 mt-16 mx-auto letter-spacing-13' style={{ maxWidth: 840 }}>At Beck Develops, I leverage this diverse background to offer innovative, scalable software solutions that address the unique challenges and goals of my clients. My mission is to transform visions into reality through technology, delivering solutions that drive efficiency, compliance, and engagement.
          </p>        
        </div>
        <hr className='mt-16 mx-auto text-secondary-dark' style={{ maxWidth: 80 }}/>
        <div className='px-48'> 
          <p className='py-12 mt-16 mx-auto letter-spacing-13' style={{ maxWidth: 840 }}>Ready to bring your project to the next level? Connect with me. Together, let's craft software solutions that not only meet the demands of today but pave the way for future innovation.
          </p> 
        </div>
        <div className='md:flex px-24 py-12 mx-auto md:justify-center'>
          <Link to="/services">
            <button className="px-48 py-12 mt-24 primary-color-dark no-border bold br-5 w-full md:auto nav-button ">
              Not Sure What You Need? Let's Chat!
            </button>   
          </Link>
        </div>
      </div>
    </section>
    
  )
}

export default About