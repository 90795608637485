import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { isAuthenticated, error } = useSelector(state => state.auth);

  return (
    <div className='text-white'>Dashboard</div>
  )
}

export default Dashboard