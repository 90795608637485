import React from "react";

const Projects = () => {
    return (
        <>
        <div className="text-white">
            Projects
            </div>
        </>
    )
}

export default Projects