import React from 'react';
import NavItem from '../NavItem';
import { navItems } from '../structure/navigation';
import { useSelector } from 'react-redux';

const Nav = () => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const hideSidebar = () => {
        const sidebar = document.querySelector('.sidebar');
        sidebar.classList.add('hidden');
    }

    return (
        <nav>
            <div>
                <ul className="flex sidebar md:hidden hidden">
                    <li className="px-18 py-18" onClick={hideSidebar}>
                        <a href="#">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height={26}
                                width={26}
                                viewBox="0 0 384 512"
                            >
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </a>
                    </li>
                    {navItems.map((item, index) => {
                        if (item.isMenu && (!item.isPrivate || isAuthenticated)) {
                            return <NavItem key={index} path={item.path} name={item.name} element={item.element} />
                        }
                        return null;
                    })}
                </ul>
            </div>
            <div className="hidden md:block">
                <ul className="flex inline">
                    {
                        navItems.map((item, index) => {
                        if (item.isMenu && (!item.isPrivate || isAuthenticated)) {
                                return <NavItem key={index} path={item.path} name={item.name} element={item.element} /> 
                        }
                        return null;
                    })}
                </ul>
            </div>
        </nav>
    )
}

export default Nav;