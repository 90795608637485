import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    users: [],
    selectedUser: null,
}

export const fetchAllUsers = createAsyncThunk(
    'users/fetchAllUsers',
    async (_, { rejectWithValue }) => {
    try {
        const { data } = await axios.get('/api/users/')

        return data;  
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchUserById = createAsyncThunk(
    'users/fetchUserById',
    async (userId, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`/api/users/${userId}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
            state.users = action.payload;
        }),
        builder.addCase(fetchUserById.fulfilled, (state, action) => {
            state.selectedUser = action.payload; // Set the fetched user data
        });
    },
})

// export const { setAllUsers } = userSlice.actions;
export default userSlice.reducer;