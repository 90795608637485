import { configureStore } from "@reduxjs/toolkit";
import userReducer from '../features/users';
import authReducer, { fetchCurrentUser } from '../features/auth';

export const store = configureStore({
    reducer: {
        users: userReducer,
        auth: authReducer
     },
})

store.dispatch(fetchCurrentUser());
