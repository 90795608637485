import React from 'react';
import { Link } from 'react-router-dom';

const NavItem = ({ path, name, element, onClick }) => {
    return (
        <li className="mx-auto px-18 py-12 m-8" onClick={onClick}>
            <Link to={path} className="text-white no-underline nav-hover">
                {name}
            </Link>
        </li>
    )
};

export default NavItem;