import React from 'react'
import './index.css';
import { Link } from 'react-router-dom';
import { faCss3, faGithub, faJava, faJs, faNode, faReact } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HeroSection = () => {
    return (
        <section className="bg-color-dark">
            <div className="stage-cube-cont mx-img-section hidden md:flex">
                <div className="cubespinner">
                    <div className="face1">
                        
                        <FontAwesomeIcon icon={faNode} color="#DD0031" />
                    </div>
                    <div className="face2">
                        <FontAwesomeIcon icon={faJava} color="#F06529" />
                    </div>
                    <div className="face3">
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                    </div>
                     <div className="face4">
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>
                    <div className="face5">
                        <FontAwesomeIcon icon={faJs} color="#EFD81D" />
                    </div>
                    <div className="face6">
                        <FontAwesomeIcon icon={faGithub} color="#EC4D28" />
                    </div>
                </div>
            </div>
                <div className="px-24 py-12 mx-auto sm:pl-50per sm:pt-100">
                    <div className="relative" style={{ maxWidth: 540 }}>
                        <h1
                        className="text-white text-center md:text-65px"
                        style={{ fontSize: "3.5rem" }}
                        >
                        Plant Your Vision
                        <span
                            className="text-accent-dark block"
                            style={{ marginTop: "-1rem" }}
                        >
                            Harvest <span className="text-secondary-dark">Success!</span>
                        </span>
                        </h1>
                        <p
                        className="text-white mt-42 sm:text-20px md:text-20px"
                        style={{ maxWidth: 440 }}
                        >At Beck Develops, we believe every big achievement starts with a small idea — a seed. We're here to cultivate your web presence, brand identity, and online engagement, transforming your digital dreams into reality.
                        </p>
                        <Link to="/services">
                            <button className="px-24 py-12 mt-24 primary-color-dark no-border bold br-5 w-full md:auto nav-button">
                                Learn More
                            </button>   
                        </Link>
                        
                    </div>
                </div>
            </section>  
    )
}

export default HeroSection;