import React from "react";

const Services = () => {
    return (
        <>
        <div className="text-white">
            Services
            </div>
        </>
    )
}

export default Services